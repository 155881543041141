@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@keyframes glow {
  from {
    box-shadow: 0 0 0 $primary;
  }
  to {
    box-shadow: 0 0 1rem 5px $primary;
  }
}

.autocomplete {
  position: relative;

  .form-control {
    &.loading {
      animation: glow 0.5s ease-in-out infinite alternate;
    }
  }

  .autocomplete-dropdown {
    position: absolute;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: white;
    left: 0;
    right: 0;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    outline: 0;
    transition: opacity 0.1s ease;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    z-index: 4;
  }

  .autocomplete-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.1s ease;
    &:hover {
      background-color: #f5f5f5;
    }

    &--active {
      background-color: #f5f5f5;
    }

    &--selected {
      font-weight: bold;
    }
  }
}

.btn-show-more {
  padding: 0.25rem;
  font-size: 0.5rem;
  line-height: 0.5rem;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus {
  color: $white;
}

.btn-outline-primary:hover {
  color: $white
}

html, body {
      height: 100%;
      margin: 0;
    }

.cursor-pointer {
  cursor: pointer;
}
